import './style.css'
import * as THREE from 'three'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as dat from 'dat.gui'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js';
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass";
import { color } from "dat.gui";


let screenIntensity = 0;
let screenColor = "#ffffff";

//navmenu
$(document).ready(function () {
    var menuLink = $('.burger');
    var menu = $('.menu');
    var close = $('.btn-close');
    var navLink = $('.menu_nav');
    var content = $('.content');

    menuLink.click(function () {
        menu.toggleClass('menu_active');
    });

    close.click(function () {
        menu.toggleClass('menu_active');
    });

    navLink.click(function () {
        menu.toggleClass('menu_active');
    });


    content.click(function () {
        menu.toggleClass('menu_active');
    });

    const proj = document.querySelector("#proj");


    const MS = document.querySelector("#MS");


    MS.addEventListener("mouseover", event => {
        console.log(proj.src);
        proj.src = "img/MsHaircut.png";
        document.getElementById("link_site").setAttribute("href", "https://www.mshaircuts.ch/");
        screenColor = "#a58253";
        screenIntensity = 5;
    });

    const PK = document.querySelector("#PK");

    PK.addEventListener("mouseover", event => {
        console.log("Mouse in PK");
        proj.src = "img/Pkgestion.png";
        document.getElementById("link_site").setAttribute("href", "https://www.pkgestion.ch/");

        screenColor = "#34f3ff";
        screenIntensity = 6;

    });

    const WE = document.querySelector("#WE");

    WE.addEventListener("mouseover", event => {
        console.log("Mouse in WE");
        proj.src = "img/Wepsy.png";
        document.getElementById("link_site").setAttribute("href", "https://www.wepsy.ch/");

        screenColor = "#fff4f6";
        screenIntensity = 10;

    });

    const PE = document.querySelector("#PE");

    PE.addEventListener("mouseover", event => {
        console.log("Mouse in PE");
        proj.src = "img/Perriard.png";
        document.getElementById("link_site").setAttribute("href", "https://www.perriard-electricite.ch/");

        screenColor = "#7bf3ff";
        screenIntensity = 7;
    });

    const WOS = document.querySelector("#WOS");

    WOS.addEventListener("mouseover", event => {
        console.log("Mouse in WOS");
        proj.src = "img/wos.png";
        document.getElementById("link_site").setAttribute("href", "https://www.wosevents.ch/");

        screenColor = "#e3cfa8";
        screenIntensity = 6;
    });

    const RENTL = document.querySelector("#Rentl");

    RENTL.addEventListener("mouseover", event => {
        console.log("Mouse in RENTL");
        proj.src = "img/rentl.png";
        document.getElementById("link_site").setAttribute("href", "https://www.rentl.ch/");
        screenColor = "#ffdbdb";
        screenIntensity = 7;
    });

    /*MS.addEventListener("mouseout", event => {
    console.log("Mouse out");
    });*/
});
document.querySelector('body').style.overflow = "hidden";

//preloader
$(window).on('load', function () {

    $('html, body').animate({
        scrollTop: $('#fixed').offset().top
    }, 'fast');
    setTimeout(function () { $('#loading').fadeOut(1000) }, 1500);
    setTimeout(function () { document.querySelector('body').style.overflow = ""; }, 2000)



    let intersectionObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if (document.getElementById('menu_home').textContent != "Accueil") {
                    document.getElementById('text_fr').style.opacity = 0;
                }
                if (document.getElementById('menu_home').textContent != "Home") {
                    document.getElementById('text_en').style.opacity = 0;
                }
                //setTimeout(function () { obj.target.classList.add('come'); }, 300);
                intersectionObserver.unobserve(entry.target);
                console.log("trigger");
                consoleText2(['Salut, moi c\'est Hervé, je suis un développeur full stack de Suisse. N\'hésitez pas à jeter un coup d\'œil à mes projets en faisant défiler la page et n\'hésitez pas à utiliser le formulaire de contact si vous êtes intéressé par une collaboration avec moi. Au plaisir de vous lire.'], 'text_fr');
                consoleText(['Hi, I\'m Hervé, a creative full stack developer from Switzerland. Feel free to take a look at my projects by scrolling down and do not hesitate to use the contact form if you are interested in working with me. I look forward to reading you.'], 'text_en');
            }
        })
    })
    document.querySelectorAll('.animate').forEach(obj => {
        intersectionObserver.observe(obj);
    })

})

// NavMenu


/* CSS Tricks smooth scrolling : https://css-tricks.com/snippets/jquery/smooth-scrolling/ */
$('a[href*=#]:not([href=#])').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 500);
            return false;
        }
    }
});


//Custom cursor
const cursor = document.querySelector('#cursor');
const cursorCircle = cursor.querySelector('.cursor__circle');

const mouse = { x: -100, y: -100 }; // mouse pointer's coordinates
const pos = { x: 0, y: 0 }; // cursor's coordinates
const speed = 0.3; // between 0 and 1

const updateCoordinates = e => {
    mouse.x = e.clientX;
    mouse.y = e.clientY;
}

window.addEventListener('mousemove', updateCoordinates);


function getAngle(diffX, diffY) {
    return Math.atan2(diffY, diffX) * 180 / Math.PI;
}

function getSqueeze(diffX, diffY) {
    const distance = Math.sqrt(
        Math.pow(diffX, 2) + Math.pow(diffY, 2)
    );
    const maxSqueeze = 0.15;
    const accelerator = 1500;
    return Math.min(distance / accelerator, maxSqueeze);
}


const updateCursor = () => {
    const diffX = Math.round(mouse.x - pos.x);
    const diffY = Math.round(mouse.y - pos.y);

    pos.x += diffX * speed;
    pos.y += diffY * speed;

    const angle = getAngle(diffX, diffY);
    const squeeze = getSqueeze(diffX, diffY);

    const scale = 'scale(' + (1 + squeeze) + ', ' + (1 - squeeze) + ')';
    const rotate = 'rotate(' + angle + 'deg)';
    const translate = 'translate3d(' + pos.x + 'px ,' + pos.y + 'px, 0)';

    cursor.style.transform = translate;
    cursorCircle.style.transform = rotate + scale;
};

function loop() {
    updateCursor();
    requestAnimationFrame(loop);
}

requestAnimationFrame(loop);



const cursorModifiers = document.querySelectorAll('.elements');

cursorModifiers.forEach(curosrModifier => {
    curosrModifier.addEventListener('mouseenter', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.add(className);
    });

    curosrModifier.addEventListener('mouseleave', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.remove(className);
    });
});

const cursorModifierss = document.querySelectorAll('.burger');

cursorModifierss.forEach(curosrModifier => {
    curosrModifier.addEventListener('mouseenter', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.add(className);
    });

    curosrModifier.addEventListener('mouseleave', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.remove(className);
    });
});

const cursorModifiersss = document.querySelectorAll('a');

cursorModifiersss.forEach(curosrModifier => {
    curosrModifier.addEventListener('mouseenter', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.add(className);
    });

    curosrModifier.addEventListener('mouseleave', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.remove(className);
    });
});

const cursorModifierssss = document.querySelectorAll('#end_bottom');

cursorModifierssss.forEach(curosrModifier => {
    curosrModifier.addEventListener('mouseenter', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.add(className);
    });

    curosrModifier.addEventListener('mouseleave', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.remove(className);
    });
});

const cursorModifiersssss = document.querySelectorAll('.pf');

cursorModifiersssss.forEach(curosrModifier => {
    curosrModifier.addEventListener('mouseenter', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.add(className);
    });

    curosrModifier.addEventListener('mouseleave', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.remove(className);
    });
});

const cursorModifierssssss = document.querySelectorAll('.submit-btn');

cursorModifierssssss.forEach(curosrModifier => {
    curosrModifier.addEventListener('mouseenter', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.add(className);
    });

    curosrModifier.addEventListener('mouseleave', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.remove(className);
    });
});

const cursorModifiersssssss = document.querySelectorAll('.language');

cursorModifiersssssss.forEach(curosrModifier => {
    curosrModifier.addEventListener('mouseenter', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.add(className);
    });

    curosrModifier.addEventListener('mouseleave', function () {
        const className = this.getAttribute('cursor-class');
        cursor.classList.remove(className);
    });
});
//smooth scroll
// const scrollbar = Scrollbar.init(document.querySelector('#my-scrollbar'), {
//     // execute listeners synchronously to make sure they can be rendered at same tick
//     syncCallbacks: true,
// });

// scrollbar.addListener(({ offset }) => {
//     fixed.style.top = offset.y + 'px';
//     fixed.style.left = offset.x + 'px';
// });

//parallax portefolio
$(window).scroll(function () {
    var scrollTop = $(window).scrollTop();
    var oddImgPos = scrollTop / 3 + 'px';
    var evenImgPos = (scrollTop) / 4.5 + 'px';
    $('.colum:even').find("img").css('transform', 'translateY(' + evenImgPos + ')');
    // document.getElementById('para').style.transform = 'translateY(' + evenImgPos + ') !important';
    //   console.log(evenImgPos);

});


//About section
// function([string1, string2],target id,[color1,color2])    

function consoleText(words, id) {
    var visible = true;
    var con = document.getElementById('console');
    var letterCount = 1;
    var x = 1;
    var waiting = false;
    var target = document.getElementById(id)
    target.setAttribute('style', 'color:' + "white")
    window.setInterval(function () {
        if (waiting === false) {
            target.innerHTML = words[0].substring(0, letterCount)
            letterCount += x;
        }
    }, 30)
    window.setInterval(function () {
        if (visible === true) {
            con.className = 'console-underscore hidden'
            visible = false;

        } else {
            con.className = 'console-underscore'

            visible = true;
        }
    }, 300)
}
function consoleText2(words, id) {
    var visible = true;
    var con = document.getElementById('console_fr');
    var letterCount = 1;
    var x = 1;
    var waiting = false;
    var target = document.getElementById(id)
    target.setAttribute('style', 'color:' + "white")
    window.setInterval(function () {
        if (waiting === false) {
            target.innerHTML = words[0].substring(0, letterCount)
            letterCount += x;
        }
    }, 30)
    window.setInterval(function () {
        if (visible === true) {
            con.className = 'console-underscore hidden'
            visible = false;

        } else {
            con.className = 'console-underscore'

            visible = true;
        }
    }, 300)
}

const scrollAmount = getScrollPercent();
/* Menu scroll position */
$("#menu_home").click(function () {
    var speed = 0;
    if (scrollAmount <= 0.33) {
        speed = 2500
    } else if (scrollAmount > 0.3 && scrollAmount <= 0.66) {
        speed = 4000
    } else { speed = 5000 }
    $('html, body').animate({
        scrollTop: 0
    }, speed);
});
$("#menu_about").click(function () {
    var speed = 0;
    if (scrollAmount <= 0.33) {
        speed = 3000
    } else if (scrollAmount > 0.3 && scrollAmount <= 0.66) {
        speed = 3500
    } else { speed = 4500 }
    $('html, body').animate({
        scrollTop: $(document).height() / 100 * 15
    }, speed);
});
$("#menu_portfolio").click(function () {
    var speed = 0;
    if (scrollAmount <= 0.33) {
        speed = 3500
    } else if (scrollAmount > 0.3 && scrollAmount <= 0.66) {
        speed = 3000
    } else { speed = 4000 }
    $('html, body').animate({
        scrollTop: $(document).height() / 100 * 30
    }, speed);
});
$("#menu_contact").click(function () {
    var speed = 0;
    if (scrollAmount <= 0.33) {
        speed = 5000
    } else if (scrollAmount > 0.3 && scrollAmount <= 0.66) {
        speed = 4000
    } else { speed = 2500 }
    $('html, body').animate({
        scrollTop: $(document).height() / 100 * 49
    }, speed);
});



$("#FR").click(function () {
    if (document.getElementById('menu_home').textContent != "Accueil") {
        lang_fr();
    }
});

$("#EN").click(function () {
    if (document.getElementById('menu_home').textContent != "Home") {
        lang_en();
    }
});
$("#FR_mobile").click(function () {
    if (document.getElementById('acc').textContent != "Accueil") {
        lang_fr_mobile();
    }
});

$("#EN_mobile").click(function () {
    if (document.getElementById('acc').textContent != "Home") {
        lang_en_mobile();
    }
});

function lang_fr() {
    document.getElementById('home_text').innerHTML = 'Accueil';
    document.getElementById('about_text').innerHTML = 'À propos';
    document.getElementById('project_title').innerHTML = 'Mes projets';
    document.getElementById('contact_form_title').innerHTML = 'Contactez-moi';
    document.getElementById('contact_name').innerHTML = 'Nom';
    document.getElementById("submit-btn").value = "Envoyer";
    document.getElementById('thanks').innerHTML = 'Merci de votre visite.';
    document.getElementById('text_fr').style.opacity = 1;
    document.getElementById('text_en').style.opacity = 0;
    document.getElementById('about_1').style.opacity = 1;
    document.getElementById('about').style.opacity = 0;




}

function lang_en() {
    document.getElementById('home_text').innerHTML = 'Home';
    document.getElementById('about_text').innerHTML = 'About';
    document.getElementById('project_title').innerHTML = 'My projects';
    document.getElementById('contact_form_title').innerHTML = 'Get in touch';
    document.getElementById('contact_name').innerHTML = 'Name';
    document.getElementById('submit-btn').value = "SUbmit";
    document.getElementById('thanks').innerHTML = 'Thank you for your visit.';
    document.getElementById('text_fr').style.opacity = 0;
    document.getElementById('text_en').style.opacity = 1;
    document.getElementById('about').style.opacity = 1;
    document.getElementById('about_1').style.opacity = 0;


}

function lang_fr_mobile() {
    document.getElementById('home_text').innerHTML = 'Accueil';
    document.getElementById('about_text').innerHTML = 'À propos';
    document.getElementById('project_title').innerHTML = 'Mes projets';
    document.getElementById('contact_form_title').innerHTML = 'Contactez-moi';
    document.getElementById('contact_name').innerHTML = 'Nom';
    document.getElementById("submit-btn").value = "Envoyer";
    document.getElementById('thanks').innerHTML = 'Merci de votre visite.';

    document.getElementById('text_fr').style.display = "content";
    document.getElementById('about1').style.display = "none";
    document.getElementById('about1').style.opacity = 0;
    document.getElementById('about_11').style.opacity = 1;
    document.getElementById('about_11').style.display = "flex";


}

function lang_en_mobile() {
    document.getElementById('home_text').innerHTML = 'Home';
    document.getElementById('about_text').innerHTML = 'About';
    document.getElementById('project_title').innerHTML = 'My projects';
    document.getElementById('contact_form_title').innerHTML = 'Get in touch';
    document.getElementById('contact_name').innerHTML = 'Name';
    document.getElementById('submit-btn').value = "SUbmit";
    document.getElementById('thanks').innerHTML = 'Thank you for your visit.';

    document.getElementById('about1').style.opacity = 1;
    document.getElementById('about1').style.display = "flex";
    document.getElementById('about_11').style.display = "none";
    document.getElementById('about_11').style.opacity = 0;


}
/**
 * 
 * |||||||||||||||||||||||||||| THREE JS ||||||||||||||||||||||||||||
 */





// Canvas
const canvas = document.querySelector('canvas.webgl')
const canvasBG = document.querySelector('canvas.webglBG')



// Scene
const scene = new THREE.Scene();
const sceneBG = new THREE.Scene();


// Objects
var mesh;
var material;
function createModel() {
    console.log("sphere");
    var geometry = new THREE.SphereGeometry(200, 60, 40);
    material = new THREE.MeshBasicMaterial({
        transparency: 0,
        map: new THREE.TextureLoader().load('./resources/models/textures/hdr_light.png'),
        side: THREE.BackSide
    });

    mesh = new THREE.Mesh(geometry, material);
    mesh.material.transparent = true;
    mesh.material.opacity = 0;
    mesh.material.needsUpdate = true;
    mesh.doubleSided = true;
    mesh.scale.set(-1, 1, 1); // important step!
    sceneBG.add(mesh);
}
createModel();

// Materials



// Mesh
var laptop = new THREE.SkinnedMesh();
var laptopScreen = new THREE.Mesh();
var laptopBase = new THREE.Mesh();
var laptopEnv = new THREE.Mesh();

// Animation
var animations;
var envAnimations;
var mixer = new THREE.AnimationMixer();

var keysDict = [-1, -1, -1, -1, -1, -1, -1, -1, 2, 41,
-1, -1, -1, 29, -1, -1, 54, 57, 58, -1,
    42, -1, -1, -1, -1, -1, -1, 15, -1, -1,
-1, -1, 59, -1, -1, -1, -1, 56, 1, 55,
    0, -1, -1, -1, -1, -1, -1, -1, 5, 14,
    13, 12, 11, 10, 9, 8, 7, 6, -1, -1,
-1, -1, -1, -1, -1, 40, 48, 46, 38, 18,
    37, 36, 35, 23, 34, 33, 32, 50, 49, 24,
    25, 16, 19, 39, 20, 22, 47, 17, 45, 44,
    21, -1, -1, -1, -1, -1, -1, -1, -1, -1,

-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, 51, 53,
    52, -1, -1, -1, -1, -1, -1, -1, -1, -1,

-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,

-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
];

//glTF Loader
const gltfLoader = new GLTFLoader();
const url = './resources/models/scene.gltf';
gltfLoader.load(url, (gltf) => {
    laptop = gltf.scene;
    laptopScreen = laptop.getChildByName("screen")
    laptopBase = laptop.getChildByName("base")
    laptopEnv = laptop.getChildByName("Empty")
    animations = gltf.animations;





    laptop.position.set(0, -2.2, 0.2);
    laptop.rotation.set(-0.4, 0, 0);

    laptopEnv.position.set(0, -10, 0);

    //laptop.add(mesh);
    scene.add(laptop);

    mixer = new THREE.AnimationMixer(gltf.scene);

    for (let i = 65; i < 130; i++) {
        mixer.clipAction(animations[i]).play();
        //console.log(animations[i]);
    }


});


// Lights
const pointLight = new THREE.PointLight(0xffffff, 5)
pointLight.position.set(0, 0, -0.5)
scene.add(pointLight)

const pointLightScreen = new THREE.PointLight(0x000000, 0)
pointLightScreen.position.set(1.5, 2, -1.6)
scene.add(pointLightScreen)



const pointLight2 = new THREE.PointLight(0xffffff, 5)
pointLight2.position.set(0, 10, 0)
pointLight2.intensity = 2;
scene.add(pointLight2);




/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    cameraBG.aspect = sizes.width / sizes.height
    cameraBG.updateProjectionMatrix()


    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    rendererBG.setSize(sizes.width, sizes.height)
    rendererBG.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 1000)
camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 5.5;

scene.add(camera);

const cameraBG = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 1, 1000)
cameraBG.position.x = 0;
cameraBG.position.y = 0;
cameraBG.position.z = 5.5;

sceneBG.add(cameraBG);



// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */


const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})

renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

const rendererBG = new THREE.WebGLRenderer({
    canvas: canvasBG,
    alpha: true

})
rendererBG.setSize(sizes.width, sizes.height)
rendererBG.setPixelRatio(Math.min(window.devicePixelRatio, 2))

const composer = new EffectComposer(renderer);
const composerBG = new EffectComposer(rendererBG);

const pixelRatio = renderer.getPixelRatio();

const fxaaPass = new ShaderPass(FXAAShader);
fxaaPass.material.uniforms['resolution'].value.x = 1 / (window.innerWidth * pixelRatio);
fxaaPass.material.uniforms['resolution'].value.y = 1 / (window.innerHeight * pixelRatio);

const rgbPass = new ShaderPass(RGBShiftShader);
rgbPass.uniforms['amount'].value = 0.001;

const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 0.01, 0, 0.85);
bloomPass.threshold = 0.3;
bloomPass.strength = 0.1;
bloomPass.radius = 2;

composer.addPass(new RenderPass(scene, camera));
composer.addPass(new SMAAPass(window.innerWidth, window.innerHeight));
composer.addPass(fxaaPass);
//composer.addPass(bloomPass);
//composer.addPass(rgbPass);
//composer.addPass(new FilmPass(1,0.3,1500, 1))

composerBG.addPass(new RenderPass(sceneBG, cameraBG));

/**
 * Animate
 */

document.addEventListener("mousemove", onDocumentMouseMove)
let mouseX = 0;
let mouseY = 0;
let targetX = 0;
let targetY = 0;
let camMoveAmount = 0;

const windowHalfX = window.innerWidth / 2;
const windowHalfY = window.innerHeight / 2;

function onDocumentMouseMove(event) {
    mouseX = (event.clientX - windowHalfX)
    mouseY = (event.clientY - windowHalfY)
}

// indicate where each section ends in % of total scroll amount
const section0 = 0;
const section1 = 0.2;
const section2 = 0.4;
const section3 = 0.6;
const section4 = 1;
const s1stop = 0.05;
const s2stop = 0.05;
const s3stop = 0.05;
const s4stop = 0.05;

document.onkeydown = function (e) {
    console.log(e.keyCode);
    console.log(keysDict[e.keyCode])
    if (keysDict[e.keyCode] != -1 && getScrollPercent() >= section2 && getScrollPercent() <= section4) {
        mixer.clipAction(animations[keysDict[e.keyCode]]).setDuration(0.5);
        mixer.clipAction(animations[keysDict[e.keyCode]]).reset();
        mixer.clipAction(animations[keysDict[e.keyCode]]).play();
    }
}

function moveLaptop() {
    // SECTION 1
    const scrollAmount = getScrollPercent();
    //laptop position -->  x: 0 / y: -2.2 / z: 0.2
    //laptop rotation -->  x: -0.4 / y: 0 / z: 0

    //screen rotation -->  x: 0 / y: 0 / z: 0
    //base rotation -->  x: 0 / y: 0 / z: 0
    if (scrollAmount == 0) {
        console.log("scroll amount 0");
        document.getElementById("about").style.display = "none";
        laptop.position.x = 0;
        laptop.position.y = -2.2;
        laptop.position.z = 0.2;

        laptop.rotation.x = -0.4;
        laptop.rotation.y = 0;
        laptop.rotation.z = 0;

        laptopBase.rotation.x = 0.0;
        laptopBase.rotation.y = 0.0;
        laptopBase.rotation.z = 0.0;

        laptopScreen.rotation.x = 0;
        laptopScreen.rotation.y = 0;
        laptopScreen.rotation.z = 0;

    }

    if (section0 < scrollAmount && scrollAmount <= section1 - s1stop) {

        var about = document.getElementById("about");
        about.style.display = "none";

        var about_1 = document.getElementById("about_1");
        about_1.style.display = "none";

        const inner = (scrollAmount - section0) / (section1 - section0 - s1stop)
        laptop.position.y = -2.2 + 0.4 * inner;
        laptop.position.x = 2 * inner;

        laptop.rotation.x = -0.4 + 0.15 * inner;
        laptop.rotation.y = -1.6 * inner;

        laptopScreen.rotation.x = -2 * inner;
        laptopBase.rotation.x = 0.2 * inner * inner;
        laptopEnv.position.y = -10 + 10 * inner;


        pointLight.position.x = 10 * inner;
        pointLight.position.y = -5 * inner;
        pointLight.intensity = 5 - 4.7 * inner;

        pointLight2.position.z = -10 * inner;

        mesh.material.opacity = 0 + 1 * inner * inner;
        mesh.rotation.x = -0.4 + 0.15 * inner;
        mesh.rotation.y = -1.6 * inner;

        camMoveAmount = inner;

        //laptop position -->  x: 2 / y: -1.8 / z: 0.2
        //laptop rotation -->  x: -0.25 / y: -1.6 / z: 0

        //screen rotation -->  x: -2 / y: 0 / z: 0
        //base rotation -->  x: 0.2 / y: 0 / z: 0
        console.log("section 1");
        for (let i = 0; i < 64; i++) {
            mixer.clipAction(animations[i]).setDuration(1);
            mixer.clipAction(animations[i]).setLoop(THREE.LoopOnce);
        }

    }
    // stop 1
    else if (section0 < scrollAmount && scrollAmount <= section1) {

        laptop.position.x = 2;
        laptop.position.y = -1.8;
        laptop.position.z = 0.2;

        laptop.rotation.x = -0.25;
        laptop.rotation.y = -1.6;
        laptop.rotation.z = 0;

        laptopBase.rotation.x = 0.2;
        laptopBase.rotation.y = 0.0;
        laptopBase.rotation.z = 0.0;

        laptopScreen.rotation.x = -2;
        laptopScreen.rotation.y = 0;
        laptopScreen.rotation.z = 0;

        pointLight.position.x = 9.95;
        pointLight.position.y = -5;
        pointLight.position.z = -0.5;

        pointLight.position.x = 0;
        pointLight.position.y = 10;
        pointLight.position.z = -9.95;
        // console.log("------------------- Valeur absolue ------------------")
        //         console.log(laptop.position.x); 
        //         console.log(laptop.position.y);
        //         console.log(laptop.position.z); 
        //         console.log("-------------------------------------")
        //         console.log(laptop.rotation.x); 
        //         console.log(laptop.rotation.y);
        //         console.log(laptop.rotation.z); 
        //         console.log("-------------------------------------")

        //         console.log(laptopBase.rotation.x);
        //         console.log(laptopBase.rotation.y); 
        //         console.log(laptopBase.rotation.z); 
        //         console.log("-------------------------------------")

        //         console.log(laptopScreen.rotation.x); 
        //         console.log(laptopScreen.rotation.y); 
        //         console.log(laptopScreen.rotation.z);
        //         console.log("-------------------------------------")

        //         console.log(pointLight.position.x);
        //         console.log(pointLight.position.y);
        //         console.log(pointLight.position.z);
        //         console.log(pointLight.position.intensity);
        //         console.log("-------------------------------------")

        //         console.log(pointLight2.position.x);
        //         console.log(pointLight2.position.y);
        //         console.log(pointLight2.position.z);
        //         console.log(pointLight2.position.intensity);

        console.log("stop 1");
        if (document.getElementById('menu_home').textContent != "Accueil") {
            document.getElementById('text_fr').style.opacity = 0;
        }
        if (document.getElementById('menu_home').textContent != "Home") {
            document.getElementById('text_en').style.opacity = 0;
        }
        document.getElementById("about").style.zIndex = "11";
        var about = document.getElementById("about");
        about.style.display = "block";
        var about_1 = document.getElementById("about_1");
        about_1.style.display = "block";

        var portfolio = document.getElementById("portfolio");
        portfolio.style.display = "";
        portfolio.style.position = ""

        for (let i = 0; i < 64; i++) {
            if (!mixer.clipAction(animations[i]).isRunning()) {
                mixer.clipAction(animations[i]).reset();
            }
            mixer.clipAction(animations[i]).setDuration(10);
            mixer.clipAction(animations[i]).setLoop(THREE.LoopRepeat);
            mixer.clipAction(animations[i]).play();
        }


    }
    // SECTION 2
    else if (section1 < scrollAmount && scrollAmount <= section2 - s2stop) {
        document.getElementById("about").style.zIndex = "9";
        document.getElementById("about").style.display = "none";
        document.getElementById("about_1").style.display = "none";



        for (let i = 0; i < 64; i++) {
            mixer.clipAction(animations[i]).setDuration(1);
            mixer.clipAction(animations[i]).setLoop(THREE.LoopOnce);
        }

        var portfolio = document.getElementById("portfolio");
        portfolio.style.display = "none";
        portfolio.style.position = "fixed"


        const inner = (scrollAmount - section1) / (section2 - section1 - s2stop)

        //laptop.position.x = 2 - 2 * inner;
        //laptop.position.z = 0.2 + 1.8 * inner;
        //laptop.rotation.x = -0.25 + 0.55 * inner;
        //laptop.rotation.y = -1.6 - 4.7 * inner;

        laptop.position.x = 2 - 0.5 * inner;
        laptop.position.z = 0.2 - 1.8 * inner;
        laptop.rotation.x = -0.25 + 0.702 * inner;
        laptop.rotation.y = -1.6 - 4.7 * inner;

        //laptopBase.rotation.x = 0.2 - 0.1 * inner;

        laptopBase.rotation.x = 0.2 - 0.6 * inner;


        pointLight.intensity = 0.3 + 0.5 * inner;

        pointLight2.intensity = 2 + 4 * inner;
        pointLight2.position.z = -10 + 12 * inner;
        pointLight2.position.x = -5 * inner;

        screenIntensity = 0;

        mesh.rotation.x = -0.25 + 0.55 * inner;
        mesh.rotation.y = -1.6 - 4.7 * inner;

        camMoveAmount = 1 - inner;

        //laptop position -->  x: 1.5 / y: -1.8 / z: -1.6
        //laptop rotation -->  x: 0.425 / y: -6.3 / z: 0

        //screen rotation -->  x: -2 / y: 0 / z: 0
        //base rotation -->  x: -0.4 / y: 0 / z: 0
        console.log("section 2");

    }
    // stop 2
    else if (section1 < scrollAmount && scrollAmount <= section2) {




        laptop.position.x = 1.50;
        laptop.position.y = -1.8;
        laptop.position.z = -1.6;

        laptop.rotation.x = 0.45;
        laptop.rotation.y = -6.3;
        laptop.rotation.z = 0;

        laptopBase.rotation.x = -0.4;
        laptopBase.rotation.y = 0.0;
        laptopBase.rotation.z = 0.0;

        laptopScreen.rotation.x = -2;
        laptopScreen.rotation.y = 0;
        laptopScreen.rotation.z = 0;

        pointLight.position.x = 0;
        pointLight.position.y = 10;
        pointLight.position.z = -9.95;

        pointLight.position.x = -5;
        pointLight.position.y = 10;
        pointLight.position.z = 1.9;

        // console.log("------------------- Valeur absolue ------------------")
        // console.log(laptop.position.x); 
        // console.log(laptop.position.y);
        // console.log(laptop.position.z); 
        // console.log("-------------------------------------")
        // console.log(laptop.rotation.x); 
        // console.log(laptop.rotation.y);
        // console.log(laptop.rotation.z); 
        // console.log("-------------------------------------")

        // console.log(laptopBase.rotation.x);
        // console.log(laptopBase.rotation.y); 
        // console.log(laptopBase.rotation.z); 
        // console.log("-------------------------------------")

        // console.log(laptopScreen.rotation.x); 
        // console.log(laptopScreen.rotation.y); 
        // console.log(laptopScreen.rotation.z);
        // console.log("-------------------------------------")

        // console.log(pointLight.position.x);
        // console.log(pointLight.position.y);
        // console.log(pointLight.position.z);
        // console.log(pointLight.position.intensity);
        // console.log("-------------------------------------")

        // console.log(pointLight2.position.x);
        // console.log(pointLight2.position.y);
        // console.log(pointLight2.position.z);
        // console.log(pointLight2.position.intensity);

        screenIntensity = 1;


        console.log("stop 2");
        var portfolio = document.getElementById("portfolio");
        portfolio.style.display = "block";

    }
    // SECTION 3
    else if (section2 < scrollAmount && scrollAmount <= section3 - s3stop) {
        var portfolio = document.getElementById("portfolio");
        portfolio.style.display = "none";
        const inner = (scrollAmount - section2) / (section3 - section2 - s3stop)
        laptop.position.x = 1.5 - 5.8 * inner;
        laptop.position.y = -1.8 + 1.6 * inner;
        laptop.position.z = -1.6 + 1.6 * inner;

        //laptop.rotation.x = 0.3 - 0.7 * inner;
        laptop.rotation.x = 0.425 - 0.825 * inner;
        //laptop.rotation.y = 0 - 5 * inner;
        laptop.rotation.y = 0 - 5 * inner;
        laptop.rotation.z = 0 + 1.8 * inner;

        laptopBase.rotation.x = -0.4 + 0.5 * inner;

        laptopScreen.rotation.x = -2 - 4 * inner;
        laptopScreen.position.y = 0 - 0.02 * inner;

        mesh.rotation.x = 0.3 - 0.7 * inner;
        mesh.rotation.y = 0 - 5 * inner;
        mesh.rotation.z = 0 + 1.8 * inner;

        screenIntensity = 0;


        camMoveAmount = inner;
        //laptop position -->  x: -5.3 / y: -0.2 / z: 0
        //laptop rotation -->  x: -0.5 / y: -5 / z: 1.8

        //screen rotation -->  x: -6 / y: 0 / z: 0
        //base rotation -->  x: 0.1 / y: 0 / z: 0
        console.log("section 3");
        var contact = document.getElementById("get-in-touch");
        contact.style.display = "none";

    }
    // stop 3
    else if (section2 < scrollAmount && scrollAmount <= section3) {
        laptop.position.x = -4.3;
        laptop.position.y = -0.2;
        laptop.position.z = 0;

        laptop.rotation.x = -0.4;
        laptop.rotation.y = -5;
        laptop.rotation.z = 1.8;

        laptopBase.rotation.x = 0.1;
        laptopBase.rotation.y = 0.0;
        laptopBase.rotation.z = 0.0;

        laptopScreen.rotation.x = -6;
        laptopScreen.rotation.y = 0;
        laptopScreen.rotation.z = 0;

        pointLight.position.x = -5;
        pointLight.position.y = 10;
        pointLight.position.z = 1.9;

        pointLight.position.x = -5;
        pointLight.position.y = 10;
        pointLight.position.z = 1.9;

        // console.log("------------------- Valeur absolue ------------------")
        // console.log(laptop.position.x); 
        // console.log(laptop.position.y);
        // console.log(laptop.position.z); 
        // console.log("-------------------------------------")
        // console.log(laptop.rotation.x); 
        // console.log(laptop.rotation.y);
        // console.log(laptop.rotation.z); 
        // console.log("-------------------------------------")

        // console.log(laptopBase.rotation.x);
        // console.log(laptopBase.rotation.y); 
        // console.log(laptopBase.rotation.z); 
        // console.log("-------------------------------------")

        // console.log(laptopScreen.rotation.x); 
        // console.log(laptopScreen.rotation.y); 
        // console.log(laptopScreen.rotation.z);
        // console.log("-------------------------------------")

        // console.log(pointLight.position.x);
        // console.log(pointLight.position.y);
        // console.log(pointLight.position.z);
        // console.log(pointLight.position.intensity);
        // console.log("-------------------------------------")

        // console.log(pointLight2.position.x);
        // console.log(pointLight2.position.y);
        // console.log(pointLight2.position.z);
        // console.log(pointLight2.position.intensity);

        console.log("stop 3");
        var contact = document.getElementById("get-in-touch");
        contact.style.display = "block";

    }

    // SECTION 4
    else if (section3 < scrollAmount && scrollAmount <= section4 - s4stop) {
        var contact = document.getElementById("get-in-touch");
        contact.style.display = "none";
        const inner = (scrollAmount - section3) / (section4 - section3 - s4stop)
        laptop.position.x = -4.3 + 4.3 * inner * inner;
        laptop.position.y = -0.2 + 0.5 * inner * inner;
        laptop.position.z = 0 + 1 * inner * inner;

        laptop.rotation.x = -0.5 + 0.5 * inner * inner;
        laptop.rotation.y = -5 + 5 * inner * inner;
        laptop.rotation.z = 1.8 - 1.8 * inner * inner;

        laptopScreen.rotation.x = -6 + 6 * inner * inner;
        laptopScreen.position.y = -0.02 + 0.02 * inner * inner;
        laptopBase.rotation.x = 0.1 - 0.1 * inner * inner;

        laptopEnv.scale.x = 1 - inner * inner;
        laptopEnv.scale.y = 1 - inner * inner;
        laptopEnv.scale.z = 1 - inner * inner;

        mesh.rotation.x = -0.5 + 0.4 * inner * inner;
        mesh.rotation.y = -5 + 5 * inner * inner;
        mesh.rotation.z = 1.8 - 1.8 * inner * inner;

        camera.position.z = 5.5 + 5 * inner * inner;

        camMoveAmount = 1 - inner * inner;
        console.log("section 4");
        var contact = document.getElementById("footer");
        contact.style.display = "none";
        mesh.material.opacity = 1 - 1 * inner * inner;

        pointLight.position.x = 10 - 10 * inner;
        pointLight2.position.x = -5 + 5 * inner;

        pointLight.intensity = 1 - 1 * inner;

    }
    // stop 4
    else if (section3 < scrollAmount && scrollAmount <= section4) {
        laptop.position.x = 0;
        laptop.position.y = 0.3;
        laptop.position.z = 1;

        laptop.rotation.x = 0;
        laptop.rotation.y = 0;
        laptop.rotation.z = 0;

        laptopBase.rotation.x = -0.3;
        laptopBase.rotation.y = 0.0;
        laptopBase.rotation.z = 0.0;

        laptopScreen.rotation.x = 0;
        laptopScreen.rotation.y = 0;
        laptopScreen.rotation.z = 0;

        pointLight.position.x = 0;
        pointLight.position.y = 10;
        pointLight.position.z = 1.9;

        pointLight.position.x = 0;
        pointLight.position.y = 10;
        pointLight.position.z = 1.95;

        // console.log("------------------- Valeur absolue ------------------")
        // console.log(laptop.position.x); 
        // console.log(laptop.position.y);
        // console.log(laptop.position.z); 
        // console.log("-------------------------------------")
        // console.log(laptop.rotation.x); 
        // console.log(laptop.rotation.y);
        // console.log(laptop.rotation.z); 
        // console.log("-------------------------------------")

        // console.log(laptopBase.rotation.x);
        // console.log(laptopBase.rotation.y); 
        // console.log(laptopBase.rotation.z); 
        // console.log("-------------------------------------")

        // console.log(laptopScreen.rotation.x); 
        // console.log(laptopScreen.rotation.y); 
        // console.log(laptopScreen.rotation.z);
        // console.log("-------------------------------------")

        // console.log(pointLight.position.x);
        // console.log(pointLight.position.y);
        // console.log(pointLight.position.z);
        // console.log(pointLight.position.intensity);
        // console.log("-------------------------------------")

        // console.log(pointLight2.position.x);
        // console.log(pointLight2.position.y);
        // console.log(pointLight2.position.z);
        // console.log(pointLight2.position.intensity);


        console.log("stop 4");
        var contact = document.getElementById("footer");
        contact.style.display = "block";

    }

    else {
        // var contact = document.getElementById("footer");
        //contact.style.display="none";
    }
}

function getScrollPercent() {
    var h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';
    return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight);
}

document.body.onscroll = moveLaptop;


const clock = new THREE.Clock();

const tick = () => {
    var delta = clock.getDelta(); // clock is an instance of THREE.Clock

    targetX = mouseX * 0.0002 * camMoveAmount;
    targetY = mouseY * 0.0002 * camMoveAmount;

    const elapsedTime = clock.getElapsedTime();

    // Update objects
    if (laptop) mixer.update(delta);
    pointLightScreen.intensity = screenIntensity;
    pointLightScreen.color.set(screenColor);

    var scroll = getScrollPercent();
    camera.position.x += (0.1 * (targetX - camera.position.x));// * (scroll / 0.1);
    camera.position.y -= (0.1 * (targetY + camera.position.y));// * (scroll / 0.1);

    laptopEnv.rotation.y += 0.0002;
    mesh.rotation.y += 0.0001;

    // Render
    composer.render(scene, camera);
    composerBG.render(sceneBG, cameraBG)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

/* Mobile JS */
